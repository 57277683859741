<template>
  <div>
    <h3 class="mb-0">
      Reset Password
    </h3>
    <p>
      Enter the new password.
    </p>
    <ValidationObserver ref="resetForm">
      <form>
        <div class="form-group">
          <label for="otp">Password Reset OTP:</label>
          <ValidationProvider name="Activation Code" rules="required" v-slot="{ errors }">
            <input type="Password" class="form-control" id="npass" v-model="otp" placeholder="Enter OTP Here">
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label for="npass">New Password:</label>
          <ValidationProvider name="New Password" rules="required" v-slot="{ errors }">
            <input type="Password" class="form-control" id="npass" v-model="newPassword">
            <span class="text-danger">{{ errors[0] }}</span>
            <span class="text-danger" v-if=" confirmPassword && newPassword  && newPassword !== confirmPassword">Password must be same. Confirm password doesn't match</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label for="vpass">Verify Password:</label>
          <ValidationProvider name="Confirm Password" rules="required" v-slot="{ errors }">
            <input type="Password" class="form-control" id="vpass" v-model="confirmPassword">
            <span class="text-danger" >{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="" style="text-align: right;">
          <button type="button" @click="changePassword" class="btn btn-primary mr-2">
            Submit
          </button>
        </div>
        <div class="sign-info" style="text-align:right">
          <span class="dark-color d-block line-height-2" style="text-align:left">Already Have An Account ? <router-link :to="{ name: 'auth.login'}">Sign In</router-link></span>
          <ul class="iq-social-media mt-4">
            <li>
              <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
            </li>
            <li>
              <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
            </li>
            <li>
              <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
            </li>
          </ul>
        </div><!-- sign-info -->
      </form>
    </ValidationObserver>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>

<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
  .sign-info{
    margin-top:0px;
    border: 0px;
    padding-top:0px;
  }
</style>

<script>
import { ValidationProvider } from "vee-validate"
import { User } from "../FackApi/api/user.js"
import ApiResponse from "../Utils/apiResponse.js"

export default {
  name: "ResetPassword",
  components: {
    ValidationProvider
  },
  data: () => ({
    showToast: false,
    toastMsg: null,
    toastVariant: "default",
    toastTitle: "Reset Password",
    showPasswordField: false,
    email: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
    token: "",
    errorFlag: false

  }),
  mounted () {
    this.token = this.$router.query ? this.$router.query.token : " "
    this.userEmail = this.userData.user_email
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  methods: {
    /**
     * changePassword
     */
    async changePassword () {
      if (this.newPassword && this.token && this.confirmPassword) {
        if (this.newPassword === this.confirmPassword) {
          let profileEditResp = await User.userResetPassword(this, this.token, this.newPassword, this.otp, this.userEmail)
          await ApiResponse.responseMessageDisplay(this, profileEditResp)
          if (profileEditResp.resp_status) {
            this.$router.push("/login")
          }
        // if password doest match with confirm password
        }
        else {
          this.errorFlag = true
        }
      }
      else {
        this.errorFlag = true
      }
    }
  }
}
</script>
